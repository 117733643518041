import { useState, useEffect } from 'react';

const CustomSlider = ({trackDuration, trackPosition, onDotDrag}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [style, setStyle] = useState({})

    //unsused drag functions
    const handleDotDragStart = () => {
        setIsDragging(true);
    }
    const handleDotDragEnd = () => {
        setIsDragging(false);
    }

    const handleDotDrag = (event) => {
      const newPosition = calculateNewPosition(event);
      onDotDrag(newPosition);
    };
  
    const calculateNewPosition = (event) => {
      const rect = event.target.getBoundingClientRect();
      const clientX = event.touches ? event.touches[0].clientX : event.clientX;
      return (clientX - rect.left) / rect.width;
    };

    useEffect(() => {
        if (!isDragging) {
        }
        //update dot position when it's not being dragged to sync to where the track is 
          setDotPosition(trackPosition / trackDuration)
    }, [trackPosition, trackDuration, isDragging])

    const setDotPosition = (position) => {
         // Set dot position (percentage) within the bar
         const newPosition = Math.max(0, Math.min(position, 1));
         const newPositionPercentage = newPosition * 100;
         setStyle({left: `${newPositionPercentage}%`})
    }

    return (
        <div className="custom-slider ml-4 pb-4 mt-8">
        <div className="progress-bar max-w-xs h-3 bg-slate-200 z-10" 
        onClick={handleDotDrag}
        onTouchStart={handleDotDragStart}
        onTouchEnd={handleDotDragEnd}
        onTouchMove={(event) => {
          if (isDragging) {
            event.preventDefault();
            const newPosition = calculateNewPosition(event);
            onDotDrag(newPosition);
          }
        }}
        >
          <div
            className="h-3 w-3 rounded-full bg-black"
            style={{ left: `${trackPosition/trackDuration * 100}%`, position: 'relative'}}
            // style={{ width: `${trackPosition / trackDuration * 100}%` }}
            onMouseDown={handleDotDragStart}
            onMouseUp={handleDotDragEnd}
          >
          </div>
    
        </div>

      </div>
    )
}

export default CustomSlider;



        //  {/* <div
        //     className="material-symbols-outlined"
        //     style={style}
        //     onClick={handleDotDrag}
        //     onMouseDown={handleDotDragStart}
        //     onMouseUp={handleDotDragEnd}
        //     onMouseMove={handleDotDrag}
        //   >
        //     radio_button_unchecked
        //   </div> */}