// import { Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10, Image11, Image12, Image13, Image14, Image15, Image16, Image17, Image18, Image19, Image20, Image21 } from "../../assets/mixArt"
import {useState, useEffect} from 'react'
import '../component.css';
import imageData from './artwork.json';
// import Visualizer from "../audio/visualizer"

interface trackProps {
    onTrackChange: (a: string) => void;
    soundcloudTrack: string;
}

interface TrackData {
    artist: string | any;
    title: string | any;
}

const Artwork = ({onTrackChange, soundcloudTrack}: trackProps) => {
    const [songInfo, setSongInfo] = useState<TrackData>({
        artist: '',
        title: ''
    })

    useEffect(() => {
        const matchedSong = imageData.find(image => image.link === soundcloudTrack)
        const newSongObject = {
            artist: matchedSong?.artist,
            title: matchedSong?.title
        }
        if (soundcloudTrack) {
            setSongInfo(newSongObject)
        }

    }, [soundcloudTrack])

    useEffect(() => {
        const textEl: any = document.getElementById('trackText');
        textEl.textContent = songInfo.artist + '—' + songInfo.title
    }, [songInfo])


    //change the url here with setTrackURL and gets passed to the App component
    function changeURLs(link: string) {
       onTrackChange(link)
    }

    //handling mobile hover events
    const handleMobileHover = (entry: any) => {
        if (window.innerWidth > 768) {
          setSongInfo({
            artist: entry.artist,
            title: entry.title
          });
        }
      };
    
      const handleMobileHoverOut = () => {
        if (window.innerWidth > 768) {
          setSongInfo({
            artist: '',
            title: ''
          });
        }
      };
    
    const imagelinks = imageData.map((entry, index) => ( 
        <img 
        onClick={() => changeURLs(entry.link)}
        onMouseEnter={() => handleMobileHover(entry)}
        onMouseOut={() => handleMobileHoverOut()}
        key={index}
        className="artwork-image w-96 h-72"
        src={require(`../../assets/mixArt/${entry.image}`)}
        alt={`Mix art ${index}`}
        style={{cursor:'cell'}}
        />
        ))

    return (
        <div className="music-box">
            <div className="grid-container max-w-sm sm:max-w-fit overflow-x-scroll overflow-y-hidden whitespace-nowrap mx-2">
                <div id="grid-inner" className="grid-inner flex flex-col justify-end">
                    <div id="trackText" className="artwork-info fixed w-96 sm:max-w-fit px-5 bg-amber-50 bg-opacity-75">
                    </div>
                    <div className="artwork-container flex gap-3">
                        {imagelinks}
                    </div>
                </div>
            </div>
            {/* <div className="visualizer"><Visualizer /></div> */}
        </div>
    )
}

export default Artwork;



// const imageFiles = []
// imageFiles.push( Image21, Image20, Image19, Image18, Image17, Image16,Image15, Image14, Image13, Image12, Image11, Image10, Image9, Image8, Image7, Image6, Image5, Image4, Image3, Image2, Image1)


    // const images = imageFiles.map((image, index) => (
    //         <img 
    //             onClick={log} 
    //             className="artwork-image"
    //             key={index}
    //             src={image}
    //             alt={`Mix art ${index}`}
    //         />

    // ))

    // const generateImageandSoundComponents = ({setTrackURL}: Props) => {
//     return imageData.map((entry, index) => (
//         <div key={index}>
//             <img 
//                 onClick={() => setTrackURL(entry.link)}
//                 className="artwork-image"
//                 src={require(`../assets/mixArt/${entry.image}`)}
//                 alt={`Mix art ${index}`}
//             />
//         </div>
//     ))
// }
    