const Mixes = () => {
    return (
        <>
            <div>
              
            </div>
        </>
    )
}

export default Mixes;