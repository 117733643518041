const Contact = () => {
    return (
        <div className="text-sm flex flex-col px-10 gap-5">
            <div>
                <p>management</p>
                <a href="endonesia.studio@gmail.com">endonesia.studio@gmail.com</a>
            </div>
            <div className="mt-3">
                <p>demo submission/inquiry</p>
                <a href="contact.endonesia@gmail.com">contact.endonesia@gmail.com</a>
            </div>
            <div className="mt-3">
                <a className="underline decoration-dotted" href="https://www.instagram.com/endonesia.studio">instagram</a>
            </div>
            <div className="mt-3">
                <a className="underline decoration-dotted" href="https://soundcloud.com/endonesia-studio">soundcloud</a>
            </div>
        </div>
    )
}

export default Contact;