import { useEffect, useState, createRef } from "react";
import CustomSlider from "./trackSlider";
import loadscript from 'load-script'
import './soundcloud.css'

// This page uses materialize css for the icons

export const MusicPlayer = ({soundcloudTrackURL}) => {

    const [isPlaying, setIsPlaying] = useState(false);
    const [songTitle, setSongTitle] = useState('')
    const [trackID, setTrackID] = useState('https://soundcloud.com/endonesia-studio/peacesign-keychain-em021');
    const [player, setPlayer] = useState(false);
    const [trackIDchange, setTrackIDchange] = useState(false);
    const [trackDuration, setTrackDuration] = useState(0);
    const [trackPosition, setTrackPosition] = useState(0);
    const [icon, setIcon] = useState(<i className="button-icon material-symbols-outlined">play_arrow</i>)

    //reference ro iframe element = https://reactjs.org/docs/refs-and-the-dom.html
    const iframeRef = createRef()

    //initialization to the widget and set SC event listeners
    useEffect(() => {
        loadscript('https://w.soundcloud.com/player/api.js', () => {
            const iframeEl = document.getElementById('music-player');
            const iframeElementID = iframeEl.id
            const player = window.SC.Widget(iframeElementID);
            setPlayer(player);

            const { PLAY, PLAY_PROGRESS, PAUSE } = window.SC.Widget.Events

            //can't access react state or props from within SC callback functions   
            
            player.bind( PLAY, () => {
                setIsPlaying(true);
                // player.setVolume(volume);

                //if song has changed, update state to the next SC index
                player.getCurrentSound((playerSong) => {
                    setSongTitle(playerSong.title)
                    
                });

                player.getDuration((durationMs) => {
                    if(durationMs !== 0) {
                        setTrackDuration(durationMs);
                    }
                });
            }); 

            player.bind(PAUSE, () => {
                //double check isPaused since false positives
                player.isPaused( (playerIsPaused) => {
                    if (playerIsPaused) setIsPlaying(false)
                })
            })

            player.bind(PLAY_PROGRESS, () => {
                player.getPosition((position) => {
                    setTrackPosition(position);
                })
            })

            if (soundcloudTrackURL) 
                {   
                    setTrackID(soundcloudTrackURL)
                    setTrackIDchange(!trackIDchange)
                    if (trackID !== soundcloudTrackURL) {
                        setIcon(<i className="blink-animation button-icon material-symbols-outlined">not_started</i>)
                       
                        // console.log(`new change with ${soundcloudTrackURL} and ${trackID}`)
                    }
                }
                
        })
    }, [soundcloudTrackURL])

    // integration - update SC player based on new state (e.g. play button in React section was click)
     //update SC player based on play/pause state
    useEffect(() => {
        if (!player) return
        player.isPaused( (playerIsPaused) => {
            if (isPlaying && playerIsPaused) {
                // console.log(trackDuration, trackPosition)
                player.play()
                setIcon(<i className="button-icon material-symbols-outlined">pause</i>)
            } else if (!isPlaying && playerIsPaused) {
                setIcon(<i className="button-icon material-symbols-outlined">play_arrow</i>)
            }
            else if (!isPlaying && !playerIsPaused) {
                player.pause()
                setIcon(<i className="button-icon material-symbols-outlined">play_arrow</i>)
            }
        })
    }, [isPlaying, trackDuration])

        // no playlistIndex djust seleted song in SC player playlist if playlistIndex state has changed

        //event handlers for play/pause
        const togglePlayback = () => {
            setIsPlaying(!isPlaying)

            player.isPaused((playerIsPaused) => {
                // console.log(isPlaying, playerIsPaused, trackIDchange)
            })
        }

        //handling the track position for sending where the dot is on the slider
        const handleDotDrag = (newPosition) => {
            const newPositionMs = newPosition * trackDuration

            player.seekTo(newPositionMs);
        }


    // const soundcloud_track_id = 'https://soundcloud.com/endonesia-studio/peacesign-keychain-em021';
    const soundcloud_player_url = `https://w.soundcloud.com/player/?url=${trackID}&amp`

    return (
        <div className="pb-0">
            <div className="row left-align w-screen md:w-40 max-w-xs mx-10">
                <span id="playbutton" onClick={togglePlayback} className="cursor-pointer flex flex-row items-center gap-3">
                    {/* {!isPlaying ? <i className="button-icon material-symbols-outlined">play_arrow</i> : <i className="button-icon material-symbols-outlined">pause</i>} */}
                    {icon}
                    <p className="songtitle">{songTitle}</p>
                </span>
            </div>
            <div className="frame-wrapper">
                <iframe ref={iframeRef} id="music-player" className="fixed" width="1000" height="166" allow="autoplay" src={soundcloud_player_url}></iframe>
            </div>
            <div>
                <CustomSlider 
                    trackDuration={trackDuration}
                    trackPosition={trackPosition}
                    onDotDrag={handleDotDrag}
                />
            </div>
        </div>
    )
}