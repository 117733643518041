import {useState} from 'react'
import './App.css';
import { Outlet, Link, useNavigate } from "react-router-dom";
import {MusicPlayer} from "../components/audio/soundcloud";
import Artwork from "../components/artwork/artwork";
import icon from "../assets/icon.png"
// import Video from "../components/video";

type urlProp = string

// interface ClickProps {
//   handleTrackURLChange: (event: React.MouseEvent<HTMLElement>) => void
// }

const App = () => {
  const [isMenuVisible, setMenuVisible] = useState(true);
  const [soundcloudTrackURL, setSoundcloudTrackURL] = useState('');
  const navigate = useNavigate();

  const isMobile = window.innerWidth <= 768; 

  const handleTrackURLChange = (props: urlProp) => {
    setSoundcloudTrackURL(props)
  }

  const handleMixesClick = () => {
    // Navigate to the "artwork" route when the "Mixes" link is clicked
    navigate('/mixes');
  }

  return (
    <div className="bg-slate-50 container font-gilSans font-light tracking-widest text-slate-900 max-w-full min-h-screen overflow-y-hidden flex flex-col gap-8">
      <div className="header">
        <Link to={`/`} onClick={() => setMenuVisible(true)} className="flex flex-row items-center gap-0">
        <img className="logo w-32 px-1 py-3" src={icon} alt="label logo" />   
        <span className="logo-text text-md sm:text-lg">ndonesia</span>
        </Link>
      </div>

      {/* this div handles two main divs: the left side nav which includes the player and then their respective tab content */}
      <div className="content flex flex-col sm:flex-row sm:justify-start sm:gap-10 items-start">
        {/* this dev handles the links and the music player */}
        <div className="sidebar">
          <nav className={`${isMenuVisible ? 'visible relative' : 'invisible fixed'} flex flex-col max-w-md`}
            >
            <ul className="list-none mx-10 text-xl leading-loose flex flex-col gap-5" onClick={() => isMobile ? setMenuVisible(false) : setMenuVisible(true)}>
              <li>
                <Link className="navLink" to={`about`}>about</Link>
              </li>
              <li>
                <Link className="navLink" to={`mixes`} onClick={handleMixesClick}>mixes</Link>
              </li>
              <li>
                <Link className="navLink" to={`releases`}>releases</Link>
              </li>
              <li>
                <Link className="navLink" to={`shop`}>shop</Link>
              </li>
              <li>
                <Link className="navLink" to={`contact`}>contact</Link>
              </li>
            </ul>
          </nav>
          <div className={`${isMobile ? 'fixed bottom-0 z-10 bg-slate-200/[.50] pt-3' : 'relative mt-14'}`}>
            <MusicPlayer soundcloudTrackURL={soundcloudTrackURL}/>
          </div>
        </div>
        <div className={`${isMobile && isMenuVisible ? 'invisible' : 'visible relative'} px-2 sm:px-0 sm:pr-10 mt-0 sm:mt-5 max-w-sm sm:max-w-max overflow-x-hidden`}>
          <Outlet />
            {window.location.pathname === '/mixes' 
              && ( 
                <>
                <Artwork onTrackChange={handleTrackURLChange} soundcloudTrack={soundcloudTrackURL}/>
                </> 
                )}
            {/* {window.location.pathname === '/' && (
              <>  
                <Video />
              </>
            )} */}
        </div>
      </div>
    </div>
  );

}
export default App;
