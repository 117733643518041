const About = () => {
    return (
        <>
            <div>
                <h5 className="px-5 text-sm">endonesia is an alternative art platform based in New York, NY and Seoul, Korea</h5>
            </div>
        </>
    )
}

export default About;