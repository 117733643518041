const Shop = () => {
    return (
        <>
              <div className="px-10 text-sm">
                <h5>coming soon</h5>
            </div>
        </>
    )
}

export default Shop;