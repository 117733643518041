import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider} from "react-router-dom"
import './index.css';
import ErrorPage from './error-page'
import App from './routes/App';
import Mixes from "./components/mixes";
import About from './components/about';
import Releases from './components/releases/releases';
import Shop from './components/shop';
import Contact from './components/contact';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "mixes",
        element: <Mixes />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "releases",
        element: <Releases />,
      },
      {
        path: "shop",
        element: <Shop />,
      },
      {
        path: "contact",
        element: <Contact />,
      }
    ]
  },
  
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

