import releaseData from "./releases.json"
import "./releases.css"
// import useSound from "use-sound"
import {Howl} from 'howler'
import {useState, useEffect} from "react"


interface Track {
    file: string;
    name: string;
  }
  
  interface Release {
    title: string;
    artist: string;
    bandcamp: string;
    image: string;
    files: Track[];
  }

const Releases = () => {
    const [playing, setPlaying] = useState<number | null>(null);
    const [sounds, setSounds] = useState<Howl[][] | any>();
    const [volume, setVolume] = useState(1);

    const loadSounds = (files: Track[]): Howl[] => {
        return files.map((track) => {
            const howlSound = new Howl({
                src: [require(`../../assets/album/${track.file}`)],
                volume, // starts muted
                preload: true,
            })

            howlSound.on('load', () => {
                console.log(`track ${track.name} is loaded`)
            })

            return howlSound;
        })
    }

    useEffect(() => {
        console.log('sounds are loading')
        setSounds(releaseData.map((release) => loadSounds(release.files)))
    }, []);
    
    const handlePlay = (releaseIndex: number, trackIndex: number) => {
        const sound = sounds[releaseIndex][trackIndex];

        if (playing !== null && playing !== trackIndex) {
            sounds?.forEach((releaseSounds: any[]) => {
                releaseSounds.forEach((s) => s.stop())
            })
        }

        if (playing === trackIndex) {
              // If the same track is clicked, toggle play/pause
              if (sound.playing()) {
                sound.pause();
              } else {
                sound.play();
                setVolume(1);
              }
        } else {
            //start playback of new track
            sound.play();
            setVolume(1);

        }

        setPlaying(playing === trackIndex ? null : trackIndex)

    }

    // const tracks = releaseData.map((release) => 
    //         release.files.map((track) => {
    //         // eslint-disable-next-line react-hooks/rules-of-hooks
    //         const [play, { stop} ] = useSound(require(`../../assets/album/${track.file}`), {
    //             volume,
    //         })
    //         const handlePlay = () => {
    //             if (playing) {
    //                 stop();
    //             } else {
    //                 play()
    //             }
    //             setPlaying(!playing);
    //             setVolume(1);
    //         }
    //         return { name: track.name, handlePlay}
    //     })
    // )
    

    return (
        <div className="flex flex-col gap-5 max-w-sm sm:max-w-fit overflow-x-scroll overflow-y-hidden whitespace-nowrap mx-2 px-2">
                {releaseData.map((release, releaseIndex) => (
                    <div className="flex flex-row w-screen gap-5" key={releaseIndex}>
                        <div>
                            <img 
                                className="w-48 sm:w-64 max-w-sm"
                                src={require(`../../assets/album/${release.image}`)}
                            />
                        </div>
                        <div className="flex flex-col justify-between">
                            <div>
                            <div className="flex flex-col sm:flex-row sm:gap-5 sm:items-center">
                                <h1 className="text-lg font-medium">{release.title}</h1>
                                <h3 className="text-sm">{release.artist}</h3>
                            </div>
                            <div className="mt-4 text-xs leading-loose">
                                
                               {release.files.map((track, trackIndex) => (
                                    <div className="flex flex-row gap-5 cursor-cell" key={trackIndex}>
                                        <p className="hover:text-slate-300" onClick={() => handlePlay(releaseIndex, trackIndex)}>
                                            {track.name}
                                        </p>
                                    </div>
                               ))}
                               
                            </div>
                            </div>
                            <a className="text-xs underline" href={release.bandcamp} target="_blank">Buy</a>
                        </div>
                        
               
                    </div>
                ))}

        </div>
    )
}

export default Releases;



// {release.tracks.map((track) => (
//     <div className="flex flex-row gap-5 cursor-pointer">
//          {/* @ts-ignore */}
//         <p className="hover:text-slate-300" onClick={track.play}>{track}</p>
//     </div>
// ))}

// {tracks[releaseIndex].map((track, trackIndex) => (
//     <div className="flex flex-row gap-5 cursor-pointer" key={trackIndex}>
//         {/* @ts-ignore */}
//         <p className="hover:text-slate-300" onClick={track.handlePlay}>
//         {track.name} 
//         </p>

//     </div>
// ))}
